import mailmagContents from "./mailmag-contents"
import formatDate from "./format-date"

const mailmagHotel = function (data: any) {
	const contents = mailmagContents(data.contents)
	return `<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
	<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
	<title>ザ クラブ・メールマガジン</title>
	<style type="text/css">
		/* Client-specific Styles */
		#outlook a { padding: 0; } /* Force Outlook to provide a "view in browser" button. */
		body { width: 100% !important; font-family:'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'Osaka', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif; } .ReadMsgBody { width: 100%; } .ExternalClass { width: 100%; } /* Force Hotmail to display emails at full width */
		body { -webkit-text-size-adjust: none; -ms-text-size-adjust: none; } /* Prevent Webkit and Windows Mobile platforms from changing default font sizes. */
		/* Reset Styles */
		body { margin: 0; padding: 0; }
		img { height: auto; line-height: 100%; outline: none; text-decoration: none; }
		#backgroundTable { height: 100% !important; margin: 0; padding: 0; width: 100% !important; }
		table td { border-collapse: collapse; }
		a img { border: none; }
		.yshortcuts, .yshortcuts a, .yshortcuts a:link,.yshortcuts a:visited, .yshortcuts a:hover, .yshortcuts a span { color: black; text-decoration: none !important; border-bottom: none !important; background: none !important; } /* Body text color for the New Yahoo.This example sets the font of Yahoo's Shortcuts to black. */
	</style>
</head>
<body>
<table border="0" cellspacing="0" cellpadding="0" align="center" bgcolor="#ddd5c6" width="100%" height="100%">
<tr>
<td>
<table width="660" border="0" cellspacing="0" cellpadding="0" align="center">
	<tr class="title">
		<td align="center" valign="top">
			<table width="660" border="0" cellpadding="0" cellspacing="0" bgcolor="#FFFFFF">
				<tr><td height="30"></td></tr>
				<tr>
					<td width="272"></td>
					<td width="117" align="center" valign="top" height="100" style="padding:0px 0px 30px 0px"><img src="https://images.microcms-assets.io/assets/319c608d4e9c445a9a79cf6d4f07a336/5d345373189f4c1eab4ea842ab05efd8/mailmag_h.jpg" width="117" height="100" alt="THE Club ROYAL PARK HOTELS" border="0"></td>
					<td width="241" align="center" valign="top" height="100" style="color:#572006; font-size:13px; line-height:25px; text-align:right; padding-right:30px;">Japanese text only</td>
				</tr>
				<tr>
					<td colspan="3" width="600" align="center" valign="top" style="color:#572006; font-size:14px; line-height:25px; text-align:left; padding:0px 30px 30px 30px;">%name% さま</td>
				</tr>
        ${data.subtitle ? `
        <tr>
          <td colspan="3" width="660" align="center" valign="top" style="color:#572006; font-size:25px; font-weight:bold; line-height:25px; text-align:center; padding:0 0 30px 0;">${data.subtitle.replace(/\n/g, '<br />')}</td>
				</tr>
        `: ``}
			</table>
		</td>
	</tr>
	${data.classification == 'ポイント残高メルマガ' ? `
<tr class="box_point">
		<td style="background:#fff;padding:0 0 30px 0;">
			<table width="560" align="center" border="0" cellspacing="0" cellpadding="0">
				<tbody><tr>
					<td align="center" style="background:#c7b491; padding:20px;">
						<table width="560" border="0" cellspacing="0" cellpadding="0">
							<tbody><tr>
								<td width="360" align="center" valign="middle" style="background:#fff; color:#572006; font-size:14px; line-height: 25px; text-align: left; padding:10px 0 10px 10px;">会員ステージ<br>Stage</td>
								<td width="200" align="center" valign="middle" style="background:#fff; color:#572006; font-size:14px; line-height: 25px; text-align: right; padding:10px 10px 10px 0;">%rank% / %rank_en%</td>
							</tr>
							<tr><td height="10"></td></tr>
							<tr>
								<td width="360" align="center" valign="middle" style="background:#fff; color:#572006; font-size:14px; line-height: 25px; text-align: left; padding:10px 0 10px 10px;">ポイント残高<br>Point Balance</td>
								<td width="200" align="center" valign="middle" style="background:#fff; color:#572006; font-size:14px; line-height: 25px; text-align: right; padding:10px 10px 10px 0;">%point% Pt</td>
							</tr>
						</tbody></table>
					</td>
				</tr>
			</tbody></table>
		</td>
	</tr>
	`: ``}
${contents}
	<tr class="footer_img">
		<td width="660" height="130" align="center" valign="bottom" style="background:#fff;">
			<img src="https://images.microcms-assets.io/assets/319c608d4e9c445a9a79cf6d4f07a336/01341d6c02f545d193c5a19555423188/mailmag_f.jpg" alt="ROYAL PARK HOTELS" width="660" height="80" border="0">
		</td>
	</tr>
	<tr class="footer_txt">
		<td width="660" style="color:#572006; font-size:12px; padding: 20px 30px 20px 30px; border-bottom:2px dotted #572006;">
			・本メールマガジンに掲載されている画像は全てイメージです。<br>・本メールマガジンに掲載されている内容は予告無く変更、終了する場合がございます。<br>
			・本メールマガジンは送信専用として配信しております。<br>
			　ご返信いただいても お応えすることができませんのでご了承ください。<br>
			・本メールマガジンは、各ホテルからの情報をご希望された方へ配信しております。<br>
			・本メールマガジンにお心当たりのない方は、誠にお手数でございますが、<br>
			　下記会員事務局までご連絡いただきますようお願い申し上げます。<br>
			・メールマガジン配信停止、会員登録情報の確認、変更は<a href="https://www.royalparkhotels.co.jp/the-club/login/?${data.classification == 'ポイント残高メルマガ' ? 'utm_source=ML-THECLUB-PM&utm_medium=EMAIL&utm_campaign=ML-THECLUB-PM-I1' : 'utm_source=ML-THECLUB-HM&utm_medium=EMAIL&utm_campaign=ML-THECLUB-HM-I1'}" target="_blank">会員サイト</a>ログイン後、<br>
			　会員メニューからお進みください。
		</td>
	</tr>
	<tr class="footer_contact">
		<td width="600" style="font-size:12px; padding: 20px 30px 20px 30px;">発行元<br>ザ クラブ・ロイヤルパークホテルズ会員事務局<br>Tel：0120-657-788（平日10:00〜12:30 / 13:30〜17:00）※年末年始は除く</td>
	</tr>
		<tr>
		<td width="660" align="center" style="color:#572006; font-size:12px; padding: 30px 0px 0px 0px;">Japanese text only</td>
	</tr>
	<tr class="footer_copyright">
		<td width="660" align="center" style="color:#572006; font-size:12px; padding: 10px 0px 20px 0px;">All rights reserved, Copyright(C) Royal Park Hotels and Resorts Co., Ltd.</td>
	</tr>
</table>
</td>
</tr>
</table>
</body>
</html>`
}

export default mailmagHotel