import React, { useEffect, useState, useRef } from "react"
import { Script } from "gatsby"
import queryString from "query-string"
import { LWrap } from "../../../components/_index"
import mailmagHotel from "../../../utils/mailmag-hotel"

// markup
const SubPage = ({ location }: any) => {
	const { contentId, draftKey } = queryString.parse(location.search);
	const [data, setData] = useState<any | null>(null);
	const [iframesrc, setIframesrc] = useState<any | null>(null);
	const [downloadhref, setDownloadhref] = useState<any | null>(null);
	const [htmlcode, setHtmlcode] = useState<any | null>(null);
	useEffect(() => {
		fetch(`https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/mailmagazine/${contentId}?draftKey=${draftKey}`, {
			headers: {
				'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || "",
			},
		})
			.then(res => res.json())
			.then(res => {
				setData({ res })
				const content = mailmagHotel(res);
				const blob = new Blob([content], { "type": "text/plain" });
				const blobiframe = new Blob([content], { "type": "text/html" });
				setIframesrc(window.URL.createObjectURL(blobiframe));
				setDownloadhref(window.URL.createObjectURL(blob));
				setHtmlcode(content);
			});
	}, []);

	if (data === null) {
		return null;
	}

	const clipCopy = () => {
		if (!navigator.clipboard) {
			alert("このブラウザは対応していません");
			return;
		}
		navigator.clipboard.writeText(htmlcode).then(
			() => {
				alert('コピーしました。');
			},
			() => {
				alert('コピーに失敗しました。');
			}
		);
	}

	return (
		<div>
			<style>{`
			.htmlmailheader{
				display:flex;
				align-items:center;
				padding:20px 0;
				gap:20px;
			}
			.htmlmailheader .title{
				flex:1;
				font-size:28px;
			}
      iframe.iframearea{
        border:1px solid #ccc;
        width:100%;
        height:calc(100vh - 100px);
      }
      .htmlcodearea{
        padding:100px 0;
      }
			.htmlcodearea button{
				text-decoration:underline;
			}
      .htmlcodearea pre{
        background-color:#eee;
        padding:20px;
        width:100%;
        height:200px;
        overflow:scroll;
      }
      `}</style>
			<main>
				<section>
					<LWrap>
						<div className="htmlmailheader">
							<p className="title">HTMLメールプレビュー</p>
							<p><a id="download" className="c_btn c_btn__bgBlack" href={downloadhref} download="mail.txt">HTMLダウンロード</a></p>
							<p><a href="#anchor01">HTMLコード↓</a></p>
						</div>
						<iframe className="iframearea" frameBorder="0" src={iframesrc} id="iframetarget"></iframe>
						<div className="htmlcodearea" id="anchor01">
							<h2 className="c_headingLv2 u_tac">HTMLコード</h2>
							<button type="button" onClick={clipCopy}>クリップボードにコピー</button>
							<pre>
								{htmlcode}
							</pre>
						</div>
					</LWrap>
				</section>
			</main>
		</div>

	)
}

export default SubPage;
