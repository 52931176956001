const mailmagContents = (contents: Array<any>) => {
  const html = contents.map((item, index) => {
    if (item?.fieldId) {
      return item.fieldId === 'heading' ? (
        heading(item)
      ) : item.fieldId === 'sentence' ? (
        sentence(item)
      ) : item.fieldId === 'single_image' ? (
        singleImage(item)
      ) : item.fieldId === 'two_image' ? (
        twoImage(item)
      ) : item.fieldId === 'link_button' ? (
        linkButton(item)
      ) : item.fieldId === 'hr' ? (
        hr(item)
      ) : item.fieldId === 'infoblock' ? (
        infoBlock(item)
      ) : ``
    }
  })
  return html.join('\n')
}

const heading = function (data: any) {
  const titletext = data.heading_text.replace(/\n/g, '<br />')
  return (
    data.type[0] === 'セクション' ? `
<tr class="box_st">
	<td width="660" align="center" valign="middle" style="background:#fff; font-family:'Helvetica'; color:#aa9670; font-size:30px; font-weight: bold; line-height: 30px; text-align: center; letter-spacing: 3px;padding:0 0 30px 0;">${titletext}</td>
</tr>
  ` : data.type[0] === '大' ? `
<tr class="box_sst">
	<td width="660" align="center" valign="middle" style="background:#fff; color:#572006; font-size:14px; font-weight:bold; line-height: 25px; text-align: center; padding:0 30px 30px 30px;">
  <div style="display:block; background:#572006; padding:10px; border-radius:10px; border:none;">
  <span style="color:#ffffff;"><big>${titletext}</big></span>
  </div>
  </td>
</tr>
  ` : data.type[0] === '中' ? `
<tr class="box_sst">
	<td width="660" align="center" valign="middle" style="background:#fff; color:#572006; font-size:14px; font-weight:bold; line-height: 25px; text-align: center; padding: 0px 30px 30px 30px;">
  <big>${titletext}</big>
  </td>
</tr>
  ` : data.type[0] === '小' ? `
<tr class="box_sst">
	<td width="660" align="center" valign="middle" style="background:#fff; color:#572006; font-size:14px; font-weight:bold; line-height: 25px; text-align: center; padding: 0px 30px 30px 30px;">
  ${titletext}
  </td>
</tr>
  ` : null
  )
}

const sentence = function (data: any) {
  return `
<tr class="box_txt">
	<td align="${data.align == "中央揃え" ? 'center' : 'left'}" valign="top" width="660" style="background:#fff; color:#572006; font-size:14px; line-height:25px; padding:0px 30px 30px 30px;">
${data.text && data.text.replace(/<br>/g, '<br>\n')}
	</td>
</tr>
  `
}

const singleImage = function (data: any) {
  return `
<tr class="box_img">
	<td width="600" valign="top" style="background:#fff;padding:0px 30px 30px 30px;color:#572006;">
  ${data.href ? `<a href="${data.href}" target="_blank">` : ""}
    <img src="${data.image.url}?w=600" alt="${data.caption || ""}" border="0" style="max-width:100%">
    ${data.caption ? `<div style="padding-top:10px;font-size:14px;">${data.caption}</div>` : ""}
  ${data.href ? `</a>` : ""}
  </td>
</tr>
  `
}

const twoImage = function (data: any) {
  return `
<tr class="box_img">
	<td valign="top" style="background:#fff;padding:0px 30px 30px 30px;">
    <table border="0" align="center" cellpadding="0" cellspacing="0" style="width:100%;">
      <tbody>
        <tr>
          <td style="padding-right:10px;color:#572006;" width="50%" valign="top">
  ${data.left_href ? `<a href="${data.left_href}" target="_blank">` : ""}
    <img src="${data.left_image.url}?w=600" alt="${data.left_caption || ""}" border="0" style="max-width:100%">
    ${data.left_caption ? `<div style="padding-top:10px;font-size:14px;">${data.left_caption}</div>` : ""}
  ${data.left_href ? `</a>` : ""}
          </td>
          <td style="padding-left:10px;color:#572006;" width="50%" valign="top">
  ${data.right_href ? `<a href="${data.right_href}" target="_blank">` : ""}
    <img src="${data.right_image.url}?w=600" alt="${data.right_caption || ""}" border="0" style="max-width:100%">
    ${data.right_caption ? `<div style="padding-top:10px;font-size:14px;">${data.right_caption}</div>` : ""}
  ${data.right_href ? `</a>` : ""}
          </td>
        </tr>
      </tbody>
    </table>
  </td>
</tr>
  `
}

const linkButton = function (data: any) {
  return `
<tr class="box_btn_login">
	<td style="background:#fff; padding:0px 30px 30px 30px;font-size:21px;" valign="middle" align="center">
		<p class="btn_login">
				<a href="${data.link[0].href}" ${data.link[0].blank ? `target="_blank"` : ``} style="font-family:'Helvetica'; width:220px; display:block; background-color:#aa9671; color:#fff; text-decoration:none; padding:13px 0;">
					${data.label.replace(/\n/g, '<br />')}
				</a>
			</p>
	</td>
</tr>
  `
}

const hr = function (data: any) {
  return `
<tr class="line"><td style="padding:0px 0px 30px 0px;background-color:#ffffff;"><div style="height:15px;background-color:#ddd5c6;"></div></td></tr>
  `
}

const infoBlock = function (data: any) {
  return `
<tr class="box_info">
	<td align="center" valign="top" style="padding:0px 30px 30px 30px;background-color:#fff;">
		<table border="0" align="center" cellpadding="0" cellspacing="0" style="width:100%;">
			<tbody><tr>
				<td align="left" valign="top" width="200" style="color:#572006;">
					<img src="${data.image.url}?w=280" alt="${data.caption || ""}" border="0">
          ${data.caption ? `<div style="padding-top:10px;font-size:14px;">${data.caption}</div>` : ""}
				</td>
				<td align="left" valign="top" style="color:#572006; font-size:14px; line-height: 25px; text-align: left; padding-left: 20px;">
						${data.text ? `${data.text}<br>` : ""}
            ${data.href ? `
            <div style="text-align:center;">
              <a href="${data.href}" ${data.blank ? `target="_blank"` : ``} style="display:inline-block; font-size:21px; font-family:'Helvetica'; width:220px;background-color:#aa9671; color:#fff; text-decoration:none; padding:13px 0; text-align:center;">
                ${data.label ? data.label.replace(/\n/g, '<br />') : ""}
              </a>
            </div>
            `: ``}
					
				</td>
			</tr></tbody>
    </table>
	</td>
</tr>
  `
}

export default mailmagContents